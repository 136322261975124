import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/UserContexts';
import axios from '../../api/axios';
import { Button, Card, Fade, Image, Spinner } from 'react-bootstrap';
import { Link, useHref, useParams } from 'react-router-dom';
// import Modal from 'react-bootstrap/Modal';
import MediaDetail from './EventCategories/MediaDetails';
import MembersDetail from './EventCategories/MembersDetail';
import DiscussionBoard from './EventCategories/CommentBoard';
import EventInfo from './EventCategories/EventInfo';
import WebLinksDetail from './EventCategories/WebLinkDetail';
import BasicContentDetail from './EventCategories/BasicContent';
import CountdownClockDetail from './EventCategories/CountdownClock';
import Documents from './EventCategories/Documents';
import ListDetail from './EventCategories/ListDetail';
import { Tabs, Tab } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";


export default function ExperiencePage() {
    const params = useParams();
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [experienceDetail, setExperienceDetail] = useState({});
    const [eventId, setEventId] = useState("");
    const [loading, setLoading] = useState(true);
    const { breadcrumb, setbreadcrumb } = useContext(UserContext);
    const [breadcrumbItem, setbreadcrumbItem] = useState(localStorage.getItem(process.env.REACT_APP_NAME + '_breadcrumb') || '');
    const href = useHref();
    const forumCat = localStorage.getItem(process.env.REACT_APP_NAME + '_forumCat');
    const [catId, setCatId] = useState("");
    const [activeTab, setActiveTab] = useState(0);

    // const [open, setOpen] = useState({});
    // const [unSupportedExp, setUnSupportedExp] = useState(false);
    // const [show, setShow] = useState(false);
    // const [data, setData] = useState({});
    // const [menuDetail, setMenuDetail] = useState({});
    useEffect(() => {
        let id = window.location.href.split('catId=')[1];
        if (id) {
            setCatId(id);
        } else {
            setCatId("");
        }
    }, []);


    let supportedFeatures = [
        "comment_board",
        "event_info",
        "media",
        "countdown_clock",
        "documents",
        "lists",
        "custom_web_links",
        "basic_content",
        "guest_list"];

    useEffect(() => {
        setLoading(true);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        if (params.id) {
            axios.get(`/event/${params.id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe
                    setExperienceDetail(response.data);
                    setLoading(false);
                    setEventId(response.data.id);
                }).catch(err => {
                    setLoading(false);
                    console.log("err ", err);
                });
        }
    }, [token, params.id]);

    const getSupportedFeatures = (data) => {

        let supported = supportedFeatures.find(item => item === data.machine_name);

        if (supported && (data.status == true)) {
            return true;
        } else {
            return false;
        }
    };
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };


    useEffect(() => {
        if (href === "/" && breadcrumb !== "Newsfeed") {
            setbreadcrumbItem("");
        } else {
            setbreadcrumbItem(localStorage.getItem(process.env.REACT_APP_NAME + '_breadcrumb'));
        }
    }, [breadcrumb, breadcrumbItem, params.id, href]);

    const TabScreen = ({ activeTab, children, idx, ...props }) => {
        return (
            <div
                role="tabpanel"
                {...props}
            >
                {activeTab === idx && <div>{children}</div>}
            </div>
        );
    };
    const getFeatures = (data, index) => {
        let supported = supportedFeatures.find(item => item === data.machine_name);
        if (supported && (data.status == true)) {

            return <Tab key={index}>{data.label}</Tab>
        }
    };
    return (
        <React.Fragment>

            <div className='col-12'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/forums/dashboard" className="" onClick={() => { localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', ''); setbreadcrumb(""); }}>Dashboard</Link></li>
                        {forumCat && <li className="breadcrumb-item">
                            <Link to={`/forums/category/${catId}`} className="" onClick={() => localStorage.setItem(process.env.REACT_APP_NAME + '_breadcrumb', forumCat)}>{forumCat}</Link></li>}
                        {breadcrumbItem && <li className="breadcrumb-item active" aria-current="page">{breadcrumbItem}</li>}
                    </ol>
                </nav>
            </div>
            <div className="col-lg-12">
                <div className="mb-4">
                    {loading ?
                        <div className="text-center mt-2">
                            <Spinner animation="border" variant="primary" />
                        </div> :
                        <div>
                            <div className="d-flex align-items-center mt-2">
                                <div className="flex-shrink-0">
                                    <div className="cardForum cardForum-md" >
                                        {experienceDetail && <Image src={experienceDetail.design?.event_thumbnail_url} fluid />
                                        }
                                    </div>
                                </div>
                                <div className="flex-grow-1 card-header ms-3">

                                    <h4 className="mb-0"><strong> {experienceDetail.name}</strong></h4>
                                </div>
                            </div>

                        </div>}
                </div>
                <div className='d-flex row flex-wrap card-heights ordersCard'>
                    <Tabs
                        activeTab={activeTab}
                        onTabClick={onTabClick}
                        hideNavBtnsOnMobile={false}
                    >
                        {experienceDetail.design?.menu.map((menu, index) => getFeatures(menu, index)
                        )}
                        {/* {process.env.REACT_APP_VENDOR_ID !== "135" && <Tab key={experienceDetail.design?.menu.length}>Directory</Tab>} */}
                    </Tabs>
                    {experienceDetail && experienceDetail.design?.menu.map((menu, index) => {
                        return <TabScreen activeTab={activeTab} idx={index} key={index}>
                            {getSupportedFeatures(menu) &&
                                <div className="col-lg-12 mt-4  mb-4">
                                    <div className="card h-100 px-2">
                                        {/* <div className="card-headers-new" >
                                        <h5 className="d-flex mb-0"><strong>{menu.label}</strong></h5>
                                    </div> */}
                                        <div className="card-body maxHeight ExperiencePage">

                                            {menu.machine_name === "documents" &&
                                                <Documents menuDetail={menu} data={menu} eventId={eventId} />}
                                            {menu.machine_name === "media" &&
                                                <MediaDetail eventId={eventId} data={menu} />}
                                            {menu.machine_name === "lists" &&
                                                <ListDetail menuDetail={menu} data={menu} eventId={eventId} />}
                                            {menu.machine_name === "countdown_clock" &&
                                                < CountdownClockDetail menuDetail={menu} data={menu} eventId={eventId} />}
                                            {menu.machine_name === "basic_content" && menu.entity_id !== null &&
                                                <BasicContentDetail eventId={eventId} data={menu} />}
                                            {menu.machine_name === "custom_web_links" &&
                                                <WebLinksDetail menuDetail={menu} data={menu} eventId={eventId} />}
                                            {menu.machine_name === "event_info" && menu.entity_id !== null &&
                                                <EventInfo data={menu} eventId={eventId} />}
                                            {menu.machine_name === "comment_board" && menu.entity_id === null &&
                                                <DiscussionBoard data={menu} eventId={eventId} />
                                            }
                                            {menu.machine_name === "guest_list" && menu.entity_id === null &&
                                                <MembersDetail eventId={eventId} />
                                            }

                                        </div>
                                    </div>
                                </div>
                                //  : <div className="col-lg-12 mt-4  mb-4">
                                //     <div className="card h-100">
                                //         {/* <div className="card-headers-new" >
                                //             <h5 className="d-flex mb-0"><strong>{menu.label}</strong> </h5>
                                //         </div> */}
                                //         <div className="card-body">

                                //             <div className=' py-3'>
                                //                 <p className='fw-bold'> Please download the mobile application to use this Feature.</p>
                                //             </div>

                                //         </div>
                                //     </div>
                                // </div>
                            }
                        </TabScreen>
                    })}
                    {/* <TabScreen activeTab={activeTab} idx={experienceDetail.design?.menu.length} key={experienceDetail.design?.menu.length}>
                       
                    </TabScreen> */}

                    {/* <MembersDetail eventId={eventId} />
                    {experienceDetail && experienceDetail.design?.menu.map((menu, index) => {
                        return (!getSupportedFeatures(menu) &&
                            <div className="col-lg-6  mb-4">
                                <div className="card h-100">
                                    <div className="card-headers-new" >
                                        <h5 className="d-flex mb-0"><strong>{menu.label}</strong> </h5>
                                    </div>
                                    <div className="card-body">

                                        <div className=' py-3'>
                                            <p className='fw-bold'> Please download the mobile application to use this Feature.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>)
                    })
                    } */}
                </div>
            </div>
        </React.Fragment >
    )
}