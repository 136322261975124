import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from "moment/moment";



const EventInfo = ({ eventId, data }) => {
    const params = useParams();
    const [eventsInfo, setEventsInfo] = useState({});
    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [incoming, setIncoming] = useState('1');
    const [showBox, setShowBox] = useState(true);
    const [rsvpStatus, setRsvpStatus] = useState(1);
    const [rsvpLength, setRsvpLength] = useState("");
    const [comment, setcomment] = useState("");


    useEffect(() => {
        if (data.machine_name == "event_info" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            // data.entity_id
            axios.get(`/event-info/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {

                    const startDate = new Date(response.data.start_date * 1000);
                    const endDate = new Date(response.data.end_date * 1000);

                    let eventData = {
                        name: response.data.name,
                        address: response.data.address,
                        location: response.data.location_name,
                        details: response.data.details,
                        startDate: startDate.toLocaleString(),
                        calenderStartDate: moment(startDate).format("YYYY-M-DD"),
                        calenderEndDate: moment(endDate).format("YYYY-MM-DD"),
                        calenderStartTime: moment(startDate).format("HH:mm"),
                        calenderEndTime: moment(endDate).format("HH:mm"),
                        endDate: endDate.toLocaleString(),
                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        rsvps: response.data.rsvps,
                        maybe_rsvp_enabled: response.data.maybe_rsvp_enabled,
                        yes_rsvp_available: response.data.yes_rsvp_available,
                        show_rsvp: response.data.show_rsvp,
                        meeting_url: response.data.meeting_url,
                        lat: response.data.lat,
                        lon: response.data.lon
                    };

                    setEventsInfo(eventData);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [token, data, eventId, rsvpLength]);

    const updateRsvp = (data) => {
        const rsvpDetail = eventsInfo.rsvps.find(item => item.user_id === userData.id);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,
        };
        let rawData = {
            "status": data,
            "total_participants": incoming,
            "comment": comment || rsvpDetail.comment
        };
        axios.put(`/event/${params.id}/rsvp/${rsvpDetail.id}`, rawData, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setRsvpLength(rsvpLength + 1);
                    if (data != 3) {
                        toast.success("Confirmation sent !");
                    }
                }
            }).catch(err => {
                // console.log("****err rsvp ", err);
                toast.error(err.response.data.error)
            });
    };

    // const getTotalLength = () => {
    //     const totalUsers = eventsInfo.rsvps.filter(item => item.status === 1 || item.status === 2);
    //     return totalUsers.length;
    // };

    useEffect(() => {
        if (eventsInfo.rsvps) {
            const rsvpDetail = eventsInfo.rsvps.find(item => item.user_id === userData.id);
            setRsvpStatus(rsvpDetail?.status);
            setIncoming(rsvpDetail?.total_participants);
        }
    }, [eventsInfo.rsvps, userData]);

    const showInMapClicked = (data) => {
        window.open("https://maps.google.com?q=" + data.lat + "," + data.lon);
    };
    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center  mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                < Card className='mt-3 '>

                    <Card.Body>

                        <div className="row">
                            <div className="col-md-6">

                                <Card.Title className=' mb-3 pt-0 pb-0 card-header font-25 border-0 ps-0'>{eventsInfo.name}</Card.Title>
                                <div className=" mb-3">
                                    <h6 className="font-18">
                                        <span className=''>{eventsInfo.startDate}</span>
                                        <span className='px-2'>to</span>
                                        <span className=''>{eventsInfo.endDate}</span>
                                    </h6>
                                </div>
                                <div className="mb-3">
                                    <div className="BtnBlk">
                                        <AddToCalendarButton
                                            size="4"
                                            styleLight="--btn-background: #54822a;--btn-background-hover: #54822a; --btn-text: #ffffff; --btn-text-hover: #ffffff; --btn-border:none;  --btn-border-hover:none; --btn-shadow:none; --btn-shadow-active:none; --btn-shadow-hover:none; --box-shadow:none --box-shadow-hover:none"
                                            hideIconButton
                                            hideCheckmark
                                            listStyle="modal"
                                            label="Add to calender"
                                            lightMode="bodyScheme"
                                            name={eventsInfo.name}
                                            options={['Google']}
                                            location={eventsInfo.location}
                                            startDate={eventsInfo.calenderStartDate}
                                            endDate={eventsInfo.calenderEndDate}
                                            startTime={eventsInfo.calenderStartTime}
                                            endTime={eventsInfo.calenderEndTime}
                                            timeZone={eventsInfo.timeZone}
                                        ></AddToCalendarButton>
                                    </div>
                                </div>
                                <div className="row  text-primary mb-2" style={{ cursor: "pointer" }} onClick={() => showInMapClicked(eventsInfo)}>
                                    <h5 className=' col-12 text-black'>{eventsInfo.location}</h5>
                                    <span className='col-12 text-underline'>{eventsInfo.address}</span>
                                </div>

                                <p className=" text-primary text-underline" style={{ cursor: "pointer" }} onClick={() => window.open(eventsInfo.meeting_url)}>{eventsInfo.meeting_url}</p>
                                <div className="wrapper">
                                    <base target="_blank" />
                                    <p className='font-normals' dangerouslySetInnerHTML={{ __html: eventsInfo.details }}></p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {eventsInfo.show_rsvp && <h3 className='mb-4 pt-0 pb-0 card-header font-25 border-0 ps-0'>RSVP</h3>}



                                {eventsInfo.show_rsvp && <div className="border p-3 p-md-5 rounded-20">
                                    <div className="row justify-content-center">
                                        <div className="col-md-10">
                                            <div className='row'>

                                                <ButtonGroup size="lg" className="mb-4">
                                                    <Button variant={`${(rsvpStatus === 1) ? "success" : 'secondary'}`} onClick={() => {
                                                        setShowBox(true); setRsvpStatus(1);
                                                    }}>
                                                        <svg width="35px" height="35px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" stroke-width="0.288"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-rule="evenodd" d="M8,0 C12.4183,0 16,3.58172 16,8 C16,12.4183 12.4183,16 8,16 C3.58172,16 0,12.4183 0,8 C0,3.58172 3.58172,0 8,0 Z M8,2 C4.68629,2 2,4.68629 2,8 C2,11.3137 4.68629,14 8,14 C11.3137,14 14,11.3137 14,8 C14,4.68629 11.3137,2 8,2 Z M7,8.58579 L10.2929,5.29289 C10.6834,4.90237 11.3166,4.90237 11.7071,5.29289 C12.0675615,5.65337923 12.0952893,6.22060645 11.7902834,6.61290152 L11.7071,6.70711 L7.70711,10.7071 C7.34662077,11.0675615 6.77939355,11.0952893 6.38709848,10.7902834 L6.29289,10.7071 L4.29289,8.70711 C3.90237,8.31658 3.90237,7.68342 4.29289,7.29289 C4.65337923,6.93241 5.22060645,6.90468077 5.61290152,7.20970231 L5.70711,7.29289 L7,8.58579 L10.2929,5.29289 L7,8.58579 Z"></path> </g></svg>
                                                        <p>yes</p></Button>
                                                    {eventsInfo.maybe_rsvp_enabled && <Button variant={`${(eventsInfo.maybe_rsvp_enabled) ? "dark" : "secondary"}`} onClick={() => {
                                                        setShowBox(true); setRsvpStatus(2);
                                                    }}>
                                                        <svg width="37px" height="37px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm8.423-3a.923.923 0 00-.923.923v.385a1 1 0 11-2 0v-.385A2.923 2.923 0 019.423 5H10c1.941 0 3.5 1.591 3.5 3.516 0 .927-.48 1.8-1.276 2.29l-1.7 1.046a1 1 0 01-1.048-1.704l1.7-1.046a.691.691 0 00.324-.586C11.5 7.679 10.82 7 10 7h-.577zm.587 8a1 1 0 100-2H10a1 1 0 100 2h.01z"></path> </g></svg>
                                                        <p> maybe</p>
                                                    </Button>}
                                                    <Button variant={`${(rsvpStatus === 3) ? "danger" : 'secondary'}`} onClick={() => { setShowBox(true); setRsvpStatus(3); updateRsvp(3); }}>
                                                        <svg fill="#000000" width="35px" height="35px" viewBox="0 0 32.00 32.00" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00032"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>cross-round</title> <path d="M0 16q0 3.264 1.28 6.208t3.392 5.12 5.12 3.424 6.208 1.248 6.208-1.248 5.12-3.424 3.392-5.12 1.28-6.208-1.28-6.208-3.392-5.12-5.088-3.392-6.24-1.28q-3.264 0-6.208 1.28t-5.12 3.392-3.392 5.12-1.28 6.208zM4 16q0-3.264 1.6-6.016t4.384-4.352 6.016-1.632 6.016 1.632 4.384 4.352 1.6 6.016-1.6 6.048-4.384 4.352-6.016 1.6-6.016-1.6-4.384-4.352-1.6-6.048zM9.76 20.256q0 0.832 0.576 1.408t1.44 0.608 1.408-0.608l2.816-2.816 2.816 2.816q0.576 0.608 1.408 0.608t1.44-0.608 0.576-1.408-0.576-1.408l-2.848-2.848 2.848-2.816q0.576-0.576 0.576-1.408t-0.576-1.408-1.44-0.608-1.408 0.608l-2.816 2.816-2.816-2.816q-0.576-0.608-1.408-0.608t-1.44 0.608-0.576 1.408 0.576 1.408l2.848 2.816-2.848 2.848q-0.576 0.576-0.576 1.408z"></path> </g></svg>
                                                        <p>no</p>
                                                    </Button>
                                                </ButtonGroup>
                                                {showBox && (rsvpStatus !== 0) && <React.Fragment> <div className="col-12 text-center">
                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <p className=" mt-2 fs-6 pe-3">Total Coming <br /> (including you)</p>
                                                        <input type="number" min={1} className=" border rounded border-dark border-3 fs-4 col-2 text-center p-1 ps-3" defaultValue={incoming} value={incoming} onChange={(e) => { setIncoming(e.target.value); }} />
                                                    </div>
                                                </div>
                                                    <div className="col-12 ">
                                                        <label htmlFor="comment" className="mt-3 d-block" >Enter a Comment! (Optional)</label>
                                                        <textarea type="text" name="comment" id="comment" className=" border rounded border-gray border-2 fs-4 mt-1  m-auto p-1 ps-3 d-block w-100" onChange={(e) => { setcomment(e.target.value); }} ></textarea>
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <Button className="btn btn-black py-2 px-5" onClick={() => updateRsvp(rsvpStatus)}>Submit</Button>
                                                    </div>
                                                </React.Fragment>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>
                        </div>



                    </Card.Body>
                </Card>
            }
        </React.Fragment >
    )
};
export default EventInfo;