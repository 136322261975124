import React, { createContext, useState, useEffect } from "react";
import { reactLocalStorage } from 'reactjs-localstorage';

export const UserContext = createContext({
    userData: [],
    experienceCategoryId: "",
    memberId: "",
    experienceId: "",
    connectionState: false,
    breadcrumb: "",
    isRequiredFieldsEmpty: false
});

export const AppProvider = ({ children }) => {
    const [userData, setUserData] = useState(reactLocalStorage.getObject(process.env.REACT_APP_NAME + '_userData', null));
    const [isLoggedIn, setIsLoggedIn] = useState(reactLocalStorage.getObject(process.env.REACT_APP_NAME + '_userData') || false);
    const [sessionToken, setSessionToken] = useState(localStorage.getItem(process.env.REACT_APP_NAME + '_userSessionToken') || '');
    const [experienceCategoryId, setExperienceCategoryId] = useState("");
    const [experienceId, setExperienceId] = useState("");
    const [isRequiredFieldsEmpty, setIsRequiredFieldsEmpty] = useState(false);
    const [memberId, setMemberId] = useState("");
    const [connectionState, setConnectionState] = useState(false);
    const [breadcrumb, setbreadcrumb] = useState("");

    // useEffect(() => {
    //     reactLocalStorage.setObject(process.env.REACT_APP_NAME + '_userData', userData);

    // }, [userData])

    const context = {
        userData,
        isLoggedIn,
        sessionToken,
        setSessionToken,
        setIsLoggedIn,
        setUserData,
        experienceCategoryId,
        setExperienceCategoryId,
        experienceId,
        setExperienceId,
        memberId,
        setMemberId,
        connectionState,
        setConnectionState,
        breadcrumb,
        setbreadcrumb,
        isRequiredFieldsEmpty,
        setIsRequiredFieldsEmpty
    }
    return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}