import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { UserContext } from '../../contexts/UserContexts';
import { useNavigate } from "react-router-dom";
import axios from '../../api/axios';
import { toast } from "react-toastify";


const passwordValidation = Yup.object().shape({
    email: Yup.string().email("Invaild format").required("Email is required"),

});

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setUserData, userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + '_Token');

    const Navigation = () => {
        navigate('/login');
    };
    const resetPassword = (data) => {
        let payload = {
            vendor_id: process.env.REACT_APP_VENDOR_ID,
            email: data.email

        };
        axios.post(`/reset`, payload)
            // .then(handleResponse)
            .then((response) => {
                if (response.status == "200") {
                    toast.success(response.data.message)
                    setLoading(false);
                    setTimeout(Navigation(), 2000);
                }

            }).catch(err => {
                console.log("err ", err.response);
                if (err.response.status == "406") {
                    toast.error("Email does not exist");
                }
                if (err.response.status == "422") {
                    toast.error(err.response.data.error.new_password[0]);
                }

                setLoading(false);
            });


    };


    return (
        <div className="container h-100vh pb-4 loginpages">
            <div className="row h-100 justify-content-center align-items-center">
                <div className="col-4">

                    <Formik
                        initialValues={{
                            email: '',
                            vendor_id: process.env.REACT_APP_VENDOR_ID
                        }}
                        validationSchema={passwordValidation}
                        onSubmit={(values, actions) => {
                            resetPassword(values);
                            actions.setSubmitting(false);
                            setLoading(true);
                            actions.resetForm({
                                values,
                            });
                        }} >
                        {({ errors, touched, isSubmitting }) => (
                            < Form >
                                <div className="form-group  card pb-5">
                                    <div className="card-body">
                                        <h3 className="text-center card-header mb-5">Forgot Password</h3>
                                        <div className="my-4 m-auto px-5">
                                            <label htmlFor="email">Email </label>
                                            <Field type="email" className="form-control" id="email" placeholder="Enter your email" name="email" />
                                            {errors.email && touched.email ? (<div className="text-danger mt-2">{errors.email}</div>) : null}
                                        </div>

                                        <div className="text-center">
                                            <button type="submit" className="btn btn-black px-4" disabled={loading}>{loading ? "Loading..." : "Reset password"}</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </div>
        </div >
    );
}