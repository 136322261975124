import React, { useContext, useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { UserContext } from "../../contexts/UserContexts";
import axios from "../../api/axios";
import { Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ReadMoreReact from 'read-more-react/dist/components/ReadMoreReact';


const MemberProfile = () => {
    const param = useParams();
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [loading, setLoading] = useState(false);
    const [usersList, setUsersList] = useState({});
    const [displayFields, setDisplayFields] = useState({});

    useEffect(() => {
        let payload = {
            api_token: token,
        };
        let vendor_id = userData?.vendor_id;

        axios.get(`/vendor/${vendor_id}/social-profile`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                // store user details and jwt token in local storage to keep user logged in between page refreshe
                if (response.status == "200") {
                    setDisplayFields(response.data);
                }
            }).catch(err => {
                console.log("err ", err);
            });
    }, [token]);

    useEffect(() => {
        if (param.id && token) {
            setLoading(true);
            let payload = {
                api_token: token,
                vendor_id: userData?.vendor_id,
            };
            axios.get(`/user/${param.id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe
                    if (response.status == "200") {
                        setUsersList(response.data);
                        setLoading(false);
                    }

                }).catch(err => {
                    console.log("err ", err);
                });
        }

    }, [token, param.id]);

    function isValidURL(string) {
        if (string) {
            string = string + "";

            const re = /\S+@\S+\.\S+/;
            var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
            if (res !== null) {
                if (re.test(string)) {
                    return <a href={`mailto:${string}`} target="_blank" ><Form.Control type="text" className="text-primary" value={string} /></a>
                } else {
                    return <a href={string} target="_blank" ><Form.Control type="text" className="text-primary" value={string} /></a>
                }

            }
            else {
                return <p className="text-primary">{string}</p>
            }
        }

    };
    const getUserObjectDetail = (data) => {
        if (data) {

            let userObject = Object.keys(data);
            return displayFields?.fields_to_display?.map(items => {
                if (userObject.includes(items.key)) {
                    return <div className="col-md-6" >
                        {(data[items.key]) && <Form.Group className="mb-4" controlId={items.key}>
                            <Form.Label>{items.label}</Form.Label>
                            {(items.key === 'bio') &&
                                <div className="text-primary">
                                    <ReadMoreReact text={data[items.key]}
                                        className="text-primary"
                                        min={80}
                                        ideal={100}
                                        max={200}
                                        readMoreText={<button class="btn text-white btn-sm" style={{ background: '#54822a' }}>Read More...</button>} />
                                </div>
                            }
                            {(items.key !== 'bio') && isValidURL(data[items.key])}
                        </Form.Group>
                        }
                    </div >
                } else if (usersList?.extra !== null) {
                    return <div className="col-md-6" >
                        {(data.extra[items.key]) && <Form.Group className="mb-4" controlId={items.key}>
                            <Form.Label>{items.label}</Form.Label>
                            {isValidURL(data.extra[items.key])}
                        </Form.Group>}
                    </div>
                } else {
                    return null;
                }
            });

        }
    };
    return (
        <React.Fragment>

            <div className="col-lg-6">

                <div className="card">

                    <div className="card-header px-4">
                        Member Detail
                    </div>
                    <div className="card-body py-4 px-4 px-md-5">

                        {loading ?

                            <div className="col-12  text-center mt-5">
                                <Spinner animation="border" variant="primary" />
                            </div>
                            :
                            <Form>
                                <div className="row disbleForm">
                                    <div className="col-md-6 pb-3">
                                        <label for="profilePic" className="form-label">Profile Picture</label> <br />
                                        <img alt="preview image" className="avtar-xxl img-cover" src={usersList?.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + "/assets/images/no-user.jpg"} />

                                        {usersList.blocked === 0 && <Link to={`/message/${usersList?.chat_credentials?.qb_id}`}>
                                            <a href="javascript:void(0);" className="ProMsg ms-4" ><svg xmlns="http://www.w3.org/2000/svg" fill="#d5d5d5" id="Layer_1" enable-background="new 0 0 24 24" height="30" viewBox="0 0 24 24" width="30"><g><path d="m18 1h-12c-2.757 0-5 2.243-5 5v8c0 2.414 1.721 4.434 4 4.899v3.101c0 .369.203.708.528.882.148.079.31.118.472.118.194 0 .387-.057.555-.168l5.748-3.832h5.697c2.757 0 5-2.243 5-5v-8c0-2.757-2.243-5-5-5zm-6.555 16.168-4.445 2.963v-2.131c0-.552-.448-1-1-1-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3h-6c-.07-.001-.17.006-.284.04-.12.035-.211.087-.271.128z"></path><path d="m18 7h-12c-.552 0-1 .448-1 1s.448 1 1 1h12c.552 0 1-.448 1-1s-.448-1-1-1z"></path><path d="m16 11h-8c-.552 0-1 .448-1 1s.448 1 1 1h8c.552 0 1-.448 1-1s-.448-1-1-1z"></path></g></svg></a>
                                        </Link>}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="col-12">
                                            <Form.Group className="mb-4" controlId="formFirstname">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type="email" value={usersList?.first_name} />

                                            </Form.Group>

                                        </div>
                                        <div className="col-12">
                                            <Form.Group className="mb-4" controlId="formLastname">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text" value={usersList?.last_name} />
                                            </Form.Group>

                                        </div>
                                    </div>
                                </div>
                                {usersList.blocked === 0 && <div className="row disbleForm">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-4" controlId="formEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="text" value={usersList?.email} />
                                        </Form.Group>
                                    </div>
                                    {usersList?.public && <>
                                        <h5 className="fw-bold mb-3">Social Profile :-</h5>
                                        {getUserObjectDetail(usersList)}
                                    </>}
                                </div>}

                            </Form>}

                    </div >
                </div >
            </div >

        </React.Fragment >
    );
}
export default MemberProfile;