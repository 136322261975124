import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Card, Spinner } from "react-bootstrap";

const Documents = ({ eventId, data, menuDetail }) => {

    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [animalsDetail, setAnimalsDetail] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const [documentLink, setDocumentLink] = useState("");

    useEffect(() => {
        if (data.machine_name == "documents" && data.type == "0") {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
                menu_item_id: data.id,
                published: true,
                event_id: eventId

            };
            axios.get("/documents", { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setDocumentList(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
        if (data.machine_name == "documents" && data.type == "1" && data.entity_id != null) {
            let payload = {
                api_token: token,
                vendor_id: userData.vendor_id,
            };
            axios.get(`/document/${data.entity_id}`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    setAnimalsDetail(response.data);
                    setLoading(false);
                }).catch(err => {
                    console.log("err ", err);
                });
        }
    }, [token, data, eventId]);

    const getLink = (data) => {
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id,


        };
        axios.get(`/document/${data}`, { params: payload })
            // .then(handleResponse)
            .then((response) => {
                setDocumentLink(response.data.file.url);
                window.open(response.data.file.url, "_blank");
            }).catch(err => {
                console.log("err ", err);
            });
    };

    const getFileObj = (data) => {
        let extensions = [".jpg", ".png", ".jpeg"];
        let findedext;
        extensions.find((item) => {
            if (item) {
                let fileExt = data.url.match(item);
                if (fileExt) {
                    findedext = "image";
                }
            }
        })

        if (findedext === "image") {
            return <Card.Img variant="top" style={{ height: "100%", width: "100%" }} src={animalsDetail.file?.url} />
        } else {
            return <Card.Img variant="top" style={{ height: "100%", width: "100%" }} src={process.env.REACT_APP_PUBLIC_URL + "/assets/images/docs.png"} />
        }

    };

    return (
        <React.Fragment>
            {loading ? <div className="col-12 text-center mt-2">
                <Spinner animation="border" variant="primary" />
            </div> :
                <div className="row mt-3">
                    {menuDetail.entity_id === null && documentList.map((doc) =>
                        <li className="mb-2 text-primary" style={{ cursor: "pointer" }} onClick={() => { getLink(doc.id); }}>{doc.name}</li>)}
                    {
                        menuDetail.entity_id !== null && animalsDetail &&
                        <Card className='col-6 col-md-2 mb-4' style={{ cursor: "pointer" }} onClick={() => window.open(animalsDetail.file?.url)}>
                           <div className="border rounded-20 py-2 px-4 "> <div className="cardForum px-3"> {getFileObj(animalsDetail?.file)} </div>
                            <Card.Body className='text-center px-0 py-2'>
                                <h6> {animalsDetail.file?.name}</h6>
                            </Card.Body>
                            </div>
                        </Card>
                    }
                </div>
                
            }
        </React.Fragment>
    )
}
export default Documents;