import axios from 'axios';
import CONSTANT from './constants';
axios.interceptors.request.use(function (config) {
    config.baseURL = CONSTANT.API_BASE_URL;


    if (!config.headers['Content-Type']) {
        config.headers = {
            'content-type': 'application/json'

        }
    }
    if (localStorage.getItem(process.env.REACT_APP_NAME + '_access_token')) {
        config.headers.bearer = localStorage.getItem(process.env.REACT_APP_NAME + '_access_token');

    }
   // if (process.env.REACT_APP_NAME === "dev") {
        config.headers['X-Requested-From'] = "DP";
    //}

    return config;
});

axios.interceptors.response.use(res => {
    return res;
});

export default axios;