import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { UserContext } from "../../contexts/UserContexts";

function replaceUrlParam(url, paramName, paramValue) {
    let urlData = '';
    if (url.match(".json?")) {
        urlData = url.split('token')[0] + 'token=' + paramValue
    } else {
        urlData = url.split('token')[0] + "?" + 'token=' + paramValue
    }

    return urlData;
};

const Image = ({ data }) => {
    const QB = window.QB;
    const { sessionToken } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [path, setPath] = useState("");

    const getImageurl = (fileId) => {
        return new Promise(function (resolve, reject) {
            QB.content.getInfo(fileId, function (error, result) {
                if (error) {
                    resolve("");
                }
                else {
                    let fileurl = QB.content.privateUrl(result.blob.uid);
                    resolve(fileurl);

                }
            });
        });
    }

    const getImageById = async (fileId) => {
        let fileurl = await getImageurl(fileId);
        setPath(fileurl);
        setLoading(false);
    };

    useEffect(() => {
        if (data && data.length && !data[0].url) {
            getImageById(data[0].id);
            setLoading(true);
        }

    }, [data]);

    return (
        <React.Fragment>
            {data.length > 0 && <div className="FullImg">
                {(data.length && data[0].url) &&
                    <React.Fragment>
                        {data[0].type === "image" && <img src={replaceUrlParam(data[0].url, 'token', sessionToken)} alt="image" className="img-fluid" />}
                        {data[0].type === "video" && <video width="100%" height="450" controls src={replaceUrlParam(data[0].url, 'token', sessionToken)} type="video/mp4">  </video>}
                    </React.Fragment>
                }
                {loading ? <div className="col-12 text-center mt-2">
                    <Spinner animation="border" variant="primary" />
                </div> : <React.Fragment>
                    {data[0]?.type.match("image") && !!path && <img src={path} alt="image" className="img-fluid" />}
                    {data[0]?.type.match("video") && !!path && <video width="100%" height="450" controls src={path} type="video/mp4">  </video>}
                </React.Fragment>}
            </div>

            }
        </React.Fragment>
    )
}
export default Image;