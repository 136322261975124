import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/UserContexts";
import axios from "../../../api/axios";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import ChatModal from "./Modal/ChatModal";

const MembersDetail = ({ eventId }) => {
    const [memberDetail, setMemberDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const { userData } = useContext(UserContext);
    const token = localStorage.getItem(process.env.REACT_APP_NAME + "_Token");
    const [showChat, setShowChat] = useState(false);
    const [memberId, setMemberId] = useState("");

    useEffect(() => {
        setLoading(true);
        let payload = {
            api_token: token,
            vendor_id: userData.vendor_id
        };
        if (eventId) {
            axios.get(`/event/${eventId}/users`, { params: payload })
                // .then(handleResponse)
                .then((response) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshe

                    let filteredMember = response.data.filter(item => {
                        if (item.id !== userData.id) {
                            return item;
                        }
                    });
                    setMemberDetail(filteredMember);
                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                    console.log("err ", err);
                });
        }

    }, [token, eventId]);

    return (
        <React.Fragment>
            <div className="col-lg-12 mt-4 mb-4">
                <div className="card">

                    {/* <div className="card-headers-new">
                        <h5 className="d-flex mb-0"> <strong>Directory</strong></h5>
                    </div> */}
                    <div className="card-body">
                        {loading ? <div className="col-12 text-center mt-2">
                            <Spinner animation="border" variant="primary" />
                        </div> :
                            <div className="maxHeight row justify-content-center">
                                <div className={`${showChat ? "col-5" : ""}`}>
                                    <ul className="memberList maxWidth600  maxHeightnone" style={{ listStyle: "none" }}>
                                        {memberDetail && memberDetail?.map((item) => {
                                            return <li className="border-bottom pb-3 mb-3">
                                                <div className='row align-items-center'>
                                                    <div className='col'>
                                                        <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => { setShowChat(true); setMemberId(item.id); }}>
                                                            <div className="flex-shrink-0">
                                                                <img src={item.profile_pic?.file.url || process.env.REACT_APP_PUBLIC_URL + '/assets/images/no-user.jpg'} alt="user" className="uderim" />
                                                            </div>
                                                            <div className="flex-grow-1 ms-2">
                                                                <h6 className="mb-0"><strong> {item.first_name} {item.last_name}</strong></h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-auto' style={{ cursor: 'pointer' }} onClick={() => { setShowChat(true); setMemberId(item.id); }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#d5d5d5" id="Layer_1" enableBackground="new 0 0 24 24" height="27" viewBox="0 0 24 24" width="27"><g><path d="m18 1h-12c-2.757 0-5 2.243-5 5v8c0 2.414 1.721 4.434 4 4.899v3.101c0 .369.203.708.528.882.148.079.31.118.472.118.194 0 .387-.057.555-.168l5.748-3.832h5.697c2.757 0 5-2.243 5-5v-8c0-2.757-2.243-5-5-5zm-6.555 16.168-4.445 2.963v-2.131c0-.552-.448-1-1-1-1.654 0-3-1.346-3-3v-8c0-1.654 1.346-3 3-3h12c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3h-6c-.07-.001-.17.006-.284.04-.12.035-.211.087-.271.128z" /><path d="m18 7h-12c-.552 0-1 .448-1 1s.448 1 1 1h12c.552 0 1-.448 1-1s-.448-1-1-1z" /><path d="m16 11h-8c-.552 0-1 .448-1 1s.448 1 1 1h8c.552 0 1-.448 1-1s-.448-1-1-1z" /></g></svg>
                                                    </div>

                                                </div>

                                            </li>
                                        })}
                                    </ul>
                                </div>
                                {showChat && < div className="col-5">
                                    <ChatModal showChat={showChat} memberId={memberId} />
                                </div>}
                            </div>}
                    </div>
                </div>
            </div >

        </React.Fragment >
    )
};
export default MembersDetail;